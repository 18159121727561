//Javascript

$(document).ready(function() {

    var $body = $('body');
	var page = $body.attr('data-page');
    var site = $body.attr('data-site');
	var $page = $('#page');
    



var $homeLogo = $('#js-homepage-logo');
if ($homeLogo) {
    var $note1 = $('#js-note-1'),
        $note2 = $('#js-note-2'),
        $note3 = $('#js-note-3'),
        scrollTop = 0; 

        $(window).scroll(function() {
            scrollTop = $(window).scrollTop();

            var ratio1 = scrollTop / 10; 
            $note1.css({ transform:"translateY(-" + ratio1 + "px)"});

            var ratio2 = scrollTop / 6; 
            $note2.css({ transform:"translateY(-" + ratio2 + "px)"});

            var ratio3 = scrollTop / 3; 
            $note3.css({ transform:"translateY(-" + ratio3 + "px)"});
        });

}

/* ==========================================================================
  MENU
  ========================================================================== */
	var $menuButton = $('#menuButton');
	var $menu = $('#menu');

	var isMenuActive = false;

	$menuButton.on('click', function(e){
		e.preventDefault();

		isMenuActive = !isMenuActive;

		if ( isMenuActive ) {
			stopScrolling();
			$menuButton.addClass('is-active');
			$menu.addClass('active');
			TweenMax.staggerFromTo( $('.menuMob a'), 0.4, { autoAlpha: 0, y: 20}, {ease: Power4.easeOut, autoAlpha: 1, y:0, delay:0.35 }, 0.05);
		} else {
			$menuButton.removeClass('is-active');
			$menu.removeClass('active');
			startScrolling();

		}

	});

	if (window.history && window.history.pushState) {
		window.addEventListener('popstate', function(event) {
			if (event.state) {
				var href = event.state.permalink;
				window.location.assign(href);
			}
		});
	}

	function stopScrolling() {
		$(window).disablescroll();
	}

	function startScrolling() {
		$(window).disablescroll('undo');
	}

/* ==========================================================================
  HOMEPAGE NEW
  ========================================================================== */

	$('.wave').imagesLoaded( { background: true }, function() {

		$('#rainbow').fadeIn();
		var tl = new TimelineMax({repeat:-1});
		tl.to("#wave", 60,{ x: "-100%", ease: Linear.easeNone }).to("#wave2", 60,{ x: "-100%", ease: Linear.easeNone }, '-=60');

	});


	if ( page == 'homeActual' ) {

		var $homepage = $('#homepage');
		var $colImage = $homepage.find('.homepageRow .colImage');

		$homepage.imagesLoaded({background: $colImage}, function() {
			$colImage.animate({'opacity': 1}, 200, function() { });
		});




		$(window).on('scroll', navPickup);

		function navPickup() {
			var width = $(window).width();
			var height = $(window).height();
			var st = $(window).scrollTop();

			if ( width <= 768 ) {
				var collectNavPoint = 0;
			} else {
				var collectNavPoint = height - 54;
			}

			if ( st > collectNavPoint ) {
				$('.homeActual header').addClass('active');
			} else {
				$('.homeActual header').removeClass('active');
			}

		}

		hpVenueList();

		function hpVenueList() {

			var $listViewHome = $('#listViewHome');
			var $item = $listViewHome.find('.item');

			var $location = $('#location');
			var $logo = $('#logo');
			var $venueImg = $('.venueImg');
			var $venueImg1 = $('#venueImg1');

			$item.first().addClass('active');
			$venueImg1.addClass('active');

			$item.hover(
				function() {

				$item.first().removeClass('active');
				$venueImg.removeClass('active');

				var $this = $(this);

				var	thisLocation = $this.data('location');
				var	thisLogo = $this.data('logo');
				var	thisCount = $this.data('count');

					$this.addClass('active');

					$location.text(thisLocation);
					$logo.css({'background-image': 'url(' + thisLogo + ')'});

					$venueImg.eq(thisCount).addClass('active');


				}, function() {
					$(this).removeClass('active');

				}
			);

		}

	} // end homepage


/* ==========================================================================
  AJAX
  ========================================================================== */
	var $ajaxPage = $('#ajaxPage');
	var $closeAjaxPage = $('#closeAjaxPage');
	var $loadPage = $('#loadPage');
	var $spinner = $('#spinner');

	$closeAjaxPage.on('click', function(e) {
		e.preventDefault();

		var title = "Leeds Piano Trail";
		var href = site + "trail/";
		var stateObj = { "state": "trail", "permalink": href, "title": title };
		history.pushState(stateObj, title, href);
		closeAjax();
	});

	function ajaxPage(href, title) {
		openAjax();

		var stateObj = { "state": "single", "permalink": href, "title": title };
		history.pushState(stateObj, title, href);

		$loadPage.load(href + " #single", function() {
			$spinner.css('display', 'none');
			single();
		});
	}

	function openAjax() {
		$ajaxPage.css('display', 'block').animate({'opacity': 1}, 200, function() { });
		$spinner.css('display', 'block');
	}

	function closeAjax() {
		$ajaxPage.animate({'opacity': 0}, 200, function() {
			$ajaxPage.css('display', 'none');
			$loadPage.empty();
		});
	}


/* ==========================================================================
  ABOUT
  ========================================================================== */

	if (page == 'about') {
    	var $slides = $("#slides");
    	var $slides2 = $('.slides2')
    	var $slidesMob = $('#slidesMob');

    	$slides.imagesLoaded({background: true}, function() {
    		$slides.animate({'opacity': 1}, 200, function() { });
    	});

		$slides.responsiveSlides({
			auto: true,             // Boolean: Animate automatically, true or false
			speed: 400,            	// Integer: Speed of the transition, in milliseconds
			timeout: 4000,          // Integer: Time between slide transitions, in milliseconds
			pager: false,           // Boolean: Show pager, true or false
			nav: false,             // Boolean: Show navigation, true or false
			random: false,          // Boolean: Randomize the order of the slides, true or false
			pause: false,           // Boolean: Pause on hover, true or false
			pauseControls: true,    // Boolean: Pause when hovering controls, true or false
			prevText: "Previous",   // String: Text for the "previous" button
			nextText: "Next",       // String: Text for the "next" button
			maxwidth: "",           // Integer: Max-width of the slideshow, in pixels
			navContainer: "",       // Selector: Where controls should be appended to, default is after the 'ul'
			manualControls: "",     // Selector: Declare custom pager navigation
			namespace: "slides",   // String: Change the default namespace used
			before: function(){},   // Function: Before callback
			after: function(){}     // Function: After callback
		});


    	$slides2.imagesLoaded({background: true}, function() {
    		$slides2.animate({'opacity': 1}, 200, function() { });
    	});

		$slidesMob.responsiveSlides({
			auto: true,             // Boolean: Animate automatically, true or false
			speed: 400,            	// Integer: Speed of the transition, in milliseconds
			timeout: 4000,          // Integer: Time between slide transitions, in milliseconds
			pager: false,           // Boolean: Show pager, true or false
			nav: false,             // Boolean: Show navigation, true or false
			random: false,          // Boolean: Randomize the order of the slides, true or false
			pause: false,           // Boolean: Pause on hover, true or false
			pauseControls: true,    // Boolean: Pause when hovering controls, true or false
			prevText: "Previous",   // String: Text for the "previous" button
			nextText: "Next",       // String: Text for the "next" button
			maxwidth: "",           // Integer: Max-width of the slideshow, in pixels
			navContainer: "",       // Selector: Where controls should be appended to, default is after the 'ul'
			manualControls: "",     // Selector: Declare custom pager navigation
			namespace: "slides",   // String: Change the default namespace used
			before: function(){},   // Function: Before callback
			after: function(){}     // Function: After callback
		});
	}

/* ==========================================================================
  TRAIL IN ACTION
  ========================================================================== */

  if ( page == 'trailInAction' ) {

	var $trailInActionWrapper = $('#trailInActionWrapper');
	var $trailImg = $trailInActionWrapper.find('.postBottom .image');

	var $videoImage = $trailInActionWrapper.find('.videoImage');

	$trailInActionWrapper.imagesLoaded({background: $trailImg}, function() {
		$trailImg.animate({'opacity': 1}, 200, function() { });
		$videoImage.animate({'opacity': 0.5}, 200, function() { });
	});



	var $playButton = $('#playButton');
	var $closeOverlay = $('#closeOverlay');
	var $overlayWrapper = $('#overlayWrapper');

	$playButton.on('click', function(e){
	        e.preventDefault();

	        stopScrolling();

	        var videoType = $(this).data('type');

	        if (videoType == 'YouTube') {
		         var videosrc = 'https://www.youtube.com/embed/' + $(this).data('id') + '?rel=0&autoplay=1&controls=1&vq=hd1080';
	        } else {
		        var videosrc = 'https://player.vimeo.com/video/' + $(this).data('id') + '?autoplay=1&title=0&byline=0&portrait=0';
	        }

	        $("#overlayWrapper iframe").attr('src', videosrc);

	        $overlayWrapper.fadeIn();
	    });

	    $closeOverlay.on('click', function(e){
	        e.preventDefault();
	        $overlayWrapper.fadeOut();
	        $("#overlayWrapper iframe").attr('src', '');
	        startScrolling();
	    });

	}



/* ==========================================================================
  DESIGNERS
  ========================================================================== */

  	if (page == 'designers' ) {

		var $designerWrapper = $('#designerWrapper');

		var $designerImage = $designerWrapper.find('.designerImage');

		var $designerCard = $designerWrapper.find('.designerCard');

		var imgLoad = imagesLoaded('.designerCard', { background: '.designerImage' });

		imgLoad.on( 'progress', function( instance, image ) {
			image.element.parentNode.parentNode.style.opacity = '1';
		});

	}

/* ==========================================================================
 SINGLE
  ========================================================================== */
	if (page == 'single') {
		single();
	}

	function single() {
		var $imageGallery = $('#imageGallery');
		var $images = $imageGallery.find('.image');

		var $singleWrapper = $('#singleWrapper');

		var $single = $('#single');
		var $moreInfoButtons = $single.find('a.moreInfo');
		var $checkInButtons = $single.find('a.checkInButton');
		var $keyFactsButtons = $single.find('a.keyFactsButton');

		var $facts = $('#facts');
		var $toTop = $('#toTop');

		var $mainImage = $('#mainImage');
		var $altImage = $('#altImage');

		$mainImage.imagesLoaded({background: true}, function() {
			$mainImage.animate({'opacity': 1}, 200, function() { });
		});

		$images.imagesLoaded({background: true}, function() {
			$images.animate({'opacity': 1}, 200, function() { });
		});

		$altImage.imagesLoaded({background: true}, function() {
			$altImage.animate({'opacity': 1}, 200, function() { });
		});

		$keyFactsButtons.on('click', function(e) {
			e.preventDefault();

			if (page == "map") {
				$ajaxPage.animate({
		            scrollTop: $facts.offset().top - 100 + 'px'
		        }, 300);
			} else {
				$('html, body').animate({
		            scrollTop: $facts.offset().top - 100 + 'px'
		        }, 300);
			}
		});

		$moreInfoButtons.on('click', function(e) {
			e.preventDefault();

			if (page == "map") {
				$ajaxPage.animate({
		            scrollTop: $longDesc.offset().top - 100 + 'px'
		        }, 300);
			} else {
				$('html, body').animate({
		            scrollTop: $longDesc.offset().top - 100 + 'px'
		        }, 300);
			}
		});

		$toTop.on('click', function(e) {
			e.preventDefault();

			if (page == "map") {
				$ajaxPage.animate({
		            scrollTop: 0
		        }, 300);
			} else {
				$('html, body').animate({
		            scrollTop: 0
		        }, 300);
			}
		});

		function resizeSingle() {
			if (window.innerWidth > 768) {
				$singleWrapper.height(window.innerHeight - 54);
				$images.width($('body').innerWidth()/2 - 5);
			} else {
				$singleWrapper.css('height', 'auto');
				$images.width(window.innerWidth);
			}
		}
		resizeSingle();

		$(window).on('resize', function() {
			resizeSingle();
		});
	}


/* ==========================================================================
  MAP
  ========================================================================== */
	if (page == 'map') {

		var $title = $('#title');
		var $title2 = $('#titleMob')
		var $location = $('#location');
		var $location2 = $('#location2');
		var $locationTitle = $('#locationTitle');
		var $locationTitle2 = $('#locationTitle2');
		var $info = $('#info');
		var $number = $('#number');
		var $number2 = $('#numberMob');
		var $logo = $('#logo');
		var height = $(window).height();

		var $previewImage = $('#previewImage');
		$previewImage.imagesLoaded( { background: true }, function() {
			$previewImage.animate({'opacity': 1}, 200, function() { });
		});


		var $title = $('#title');
		var $artist = $('#artist');
		var $location = $('#location');


		var $openPage = $page.find('a.openPage');

		$openPage.on('click', function(e) {
			e.preventDefault();
			var href = $(this).attr('href');
			var title = $(this).attr('data-title');
			ajaxPage(href, title);
		});

		$(window).load(function() {
			if (window.innerWidth > 768) {
				sizePreviewImage();
				$(window).on('resize', function() {
					sizePreviewImage();
				});
			}
		});

		function sizePreviewImage() {
			var h = $info.height();
			$previewImage.height(window.innerHeight - h - 54);
		}


		var $listView = $('#listView');
		var $items = $listView.find('a');
		var $mapViewButton = $('#mapViewButton');
		var $listViewButton = $('#listViewButton');
		var $controlsBG2 = $('#controlsBG2');


		$mapViewButton.on('click', function(e) {
			e.preventDefault();
			closeListView();
		});


		$listViewButton.on('click', function(e) {
			e.preventDefault();
			openListView();
		});

		function openListView() {
			if ( height < 768 ) {
				$listView.addClass('block');
				$listView.animate({'opacity': 1}, 200, function() { });
			} else {
				$listView.addClass('flex');
				$listView.animate({'opacity': 1}, 200, function() { });
			}
			$mapViewButton.removeClass('active');
			$listViewButton.addClass('active');

		}


		function closeListView() {
			if ( height < 768 ) {
				$listView.removeClass('block');
			} else {
				$listView.removeClass('flex');
			}
			$listView.animate({'opacity': 1}, 200, function() { });
			$mapViewButton.addClass('active');
			$listViewButton.removeClass('active');

		}

		//$items.first().addClass('active');

		$items.on('click', function(e) {
			e.preventDefault();

			if (window.innerWidth > 768) {
				$items.removeClass('active');
				$(this).addClass('active');

				var title = $(this).attr('data-title');
				var number = $(this).attr('data-number')
				var location = $(this).attr('data-location');
				var permalink = $(this).attr('data-permalink');
				var number = $(this).attr('data-number');
				var image = $(this).attr('data-image');
				var logo = $(this).attr('data-logo');

				$previewImage.css({'opacity': 0, 'background-image': 'url(' + image + ')'}).imagesLoaded( { background: true }, function() {
					$previewImage.animate({'opacity': 1}, 200, function() { });
				});

				$number.text(number);
				$number2.text(number);
				$title.text(title);
				$title2.text(title);

				$locationTitle.text(title);
				$locationTitle2.text(title);
				$location.text(location);
				$location2.text(location);
				$openPage.attr('href', permalink).attr('data-title', title);

				$logo.css({'background-image': 'url(' + logo + ')'});


				sizePreviewImage();
			} else {

				var href = $(this).attr('href');
				var title = $(this).attr('data-title');
				ajaxPage(href, title);

			}
		});



		var $map = $('#map');

		var $previewMob = $('#previewMob');

		var $previewImageMob = $('#previewImageMob');
		$previewImageMob.imagesLoaded( { background: true }, function() {
			$previewImageMob.animate({'opacity': 1}, 200, function() { });
		});




		$(window).load(function() {
			sizeMap();
			$(window).on('resize', function() {
				sizeMap();
			});
		});

		function sizeMap() {
			if (window.innerWidth > 768) {
				$map.width(window.innerWidth/2).height(window.innerHeight);
			} else {
				var h = $previewMob.height();
				$map.width(window.innerWidth).height(window.innerHeight - (h + 54 + 70));
			}
		}

		var overlay;
		myMarker.prototype = new google.maps.OverlayView();

		function initialize() {

			var marker;
			var markers = [];

			var map;
			var defaultLatLng = new google.maps.LatLng(53.79852174, -1.54205561);
			var bounds = new google.maps.LatLngBounds();
			var style = [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}];

			var mapOptions = {
		        zoom: 16,
		        minZoom: 2,
		        maxZoom:18,
		        center: defaultLatLng,
				disableDefaultUI: true,
				backgroundColor: 'transparent',
				styles: style
		    };

			var mapElement = document.getElementById('map');
			map = new google.maps.Map(mapElement, mapOptions);

			/*
			var marker = new google.maps.Marker({
				position: position,
				map: map,
				//icon: myIcon,
				//title: title,
			});
			*/

			// MARKERS

			$.getJSON(site + "/wp-content/themes/PianoTrail/scripts/json.php", function(data) {

				bounds = new google.maps.LatLngBounds();

				$.each(data, function(i, post) {
					var id = post.id;
					var title = post.title;
					var location = post.location;
					var latitide = post.latitude;
					var longitude = post.longitude;
					var number = post.number;
					var permalink = post.permalink;
					var artist = post.artist;
					var artistLink = post.artistLink;
					var image = post.image;
					var desc = post.desc;
					var logo = post.logo;

					var position = new google.maps.LatLng(latitide, longitude); bounds.extend(position);
					bounds.extend(position);

					overlay = new myMarker(position, number, false, permalink, title, location, artist, artistLink, image, desc, map, logo);
				});

				map.fitBounds(bounds);
			});

			var $zoomIn = $('#zoomIn');
			var $zoomOut = $('#zoomOut');

			$zoomIn.on('click', function(e) {
				e.preventDefault();
				map.setZoom(map.getZoom() + 1);
			});

			$zoomOut.on('click', function(e) {
				e.preventDefault();
				map.setZoom(map.getZoom() - 1);
			});
		}

		google.maps.event.addDomListener(window, 'load', initialize);

		function myMarker(position, number, completed, permalink, title, location, artist, artistLink, image, desc, map, logo) {
			this.position_ = position;
			this.number_ = number;
			this.completed_ = completed;
			this.permalink_ = permalink;
			this.title_ = title;
			this.artist_ = artist;
			this.artistLink_ = artistLink;
			this.location_ = location;
			this.image_ = image;

			this.logo_ = logo;

			this.map_ = map;
			this.div_ = null;

			this.setMap(map);
		}

		myMarker.prototype.onAdd = function() {
		  	var div = document.createElement('div');
		  	this.div_ = div;

		  	var panes = this.getPanes();
		  	panes.overlayMouseTarget.appendChild(div);

		  	var me = this;
		  	google.maps.event.addDomListener(div, 'click', function() {
		    	google.maps.event.trigger(me, 'click');

		    	$('.marker.active').removeClass('active');
		    	this.className = "marker active";

		    	var title = this.getAttribute('data-title');
				var location = this.getAttribute('data-location');
				var permalink = this.getAttribute('data-permalink');
				var number = this.getAttribute('data-number');
				var artist = this.getAttribute('data-artist');
				var artistLink = this.getAttribute('data-artistLink');
				var image = this.getAttribute('data-image');
				var logo = this.getAttribute('data-logo');


				if (window.innerWidth > 768) {

					$previewImage.css({'opacity': 0, 'background-image': 'url(' + image + ')'}).imagesLoaded( { background: true }, function() {
						$previewImage.animate({'opacity': 1}, 200, function() { });
					});

					$number.text(number);

					$title.html(title);

					$locationTitle.text(title);
					$location.text(location);
					$openPage.attr('href', permalink).attr('data-title', title);

					$logo.css({'background-image': 'url(' + logo + ')'});

					sizePreviewImage();
				} else {

					$previewImageMob.css({'opacity': 0, 'background-image': 'url(' + image + ')'}).imagesLoaded( { background: true }, function() {
						$previewImageMob.animate({'opacity': 1}, 200, function() { });
					});

					$number2.text(number);
					$title2.html(title);
					$location2.text(location);
					$locationTitle2.text(title);
					$openPage.attr('href', permalink).attr('data-title', title);

					$logo.css({'background-image': 'url(' + logo + ')'});

					sizeMap();
				}

			});
		};


		myMarker.prototype.draw = function() {
			var overlayProjection = this.getProjection();

			var pos = overlayProjection.fromLatLngToDivPixel(this.position_);

			var div = this.div_;
			div.style.left = (pos.x - 15) + 'px';
            
			div.style.top = (pos.y - 42) + 'px';

			if (this.number_ == 1) {
				div.className = "marker active";
			} else {
				div.className = "marker";
			}
			//div.innerHTML = this.number_;

			div.setAttribute("data-permalink", this.permalink_);
			div.setAttribute("data-title", this.title_);
			div.setAttribute("data-location", this.location_);
			div.setAttribute("data-number", this.number_);
			div.setAttribute("data-artist", this.artist_);
			div.setAttribute("data-artistLink", this.artistLink_);
			div.setAttribute("data-image", this.image_);
			div.setAttribute("data-logo", this.logo_);
		};

	}

});

